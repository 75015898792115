import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { EventsWrapper, Content, ImageContainer, CategoryWrapper, Category, LinkItem } from './styles'
import LocalizedLink from '../LocalizedLink'

const EventsContent = ({ data }) => {
  const {
    image: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "events.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  function filterCategories(catName) {
    return data.edges.filter(
      (
        {
          node: {
            fields: { slug },
            frontmatter: { title, tag }
          }
        },
        index
      ) => {
        return tag !== catName
      }
    )
  }

  return (
    <EventsWrapper>
      <Content>
        <h1>Events</h1>
        <CategoryWrapper>
          <Category>
            {data.edges.map(
              (
                {
                  node: {
                    fields: { slug },
                    frontmatter: { title, tag }
                  }
                },
                index
              ) => {
                return (
                  <LinkItem key={`${slug}${index}`}>
                    <LocalizedLink to={`/events/${slug}`} aria-label={slug}>
                      <strong>{`${title.split(',')[0]}`}</strong>
                      {`,${title.split(',').slice(1)}`}
                    </LocalizedLink>
                  </LinkItem>
                )
              }
            )}
            <LinkItem>
              <a href="https://www.youtube.com/watch?v=wdxPcmSWBXQ" target="_blank" rel="noreferrer">
                <strong>New Europe Business Forum 2019 – 16 plus 1 Youth Forum</strong>
                <br />
                Eric Li & Mato Njavro | On the US - China trade war; globalization, EU - China relations and more...
              </a>
            </LinkItem>
            <LinkItem>
              <a href="https://www.youtube.com/watch?v=P0e8hWtquhw" target="_blank" rel="noreferrer">
                <strong>New Europe Business Forum 2018</strong>
              </a>
            </LinkItem>
            <LinkItem>
              <strong>New Europe Business Forum 2017</strong>
            </LinkItem>
            <LinkItem>
              <a href="https://www.youtube.com/watch?v=X9BJtqvmgVI" target="_blank" rel="noreferrer">
                <strong>New Europe Business Forum 2016</strong>
                <br />
                George Yeo & Mato Njavro – Small is beautiful: Singapore 50 years after independence
              </a>
            </LinkItem>
          </Category>
        </CategoryWrapper>
      </Content>
      <ImageContainer bgImage={fluid.src} opacity={0.5} />
    </EventsWrapper>
  )
}

export default EventsContent
