import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/seo'
import EventsContent from 'components/Events'

const EventsTemplate = ({ data }) => {
  const { allMarkdownRemark } = data
  return (
    <>
      <SEO title="Events" />
      <EventsContent data={allMarkdownRemark} />
    </>
  )
}

export default EventsTemplate

export const query = graphql`
  query Events($locale: String!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}, 
      filter: { 
        fields: { locale: { eq: $locale } } 
        fileAbsolutePath: {regex: "/(events)\/.*\\.md$/"}
      }
    ){
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            locale
            slug
          }
        }
      }
    }
  }

`
